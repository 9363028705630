import SummaryNumbers from './SummaryNumbers'

const HR = () => {return <hr  style={{ height: .5, backgroundColor: '#ddd', borderColor : '#ddd' }}/>}

export default function ProduzioneKmReportLayout({numeriVersioneCorrente, numeriVersionePreventivo, numeriVersioneConfronto}) {
  const preventivoCorrenti = numeriVersionePreventivo ? numeriVersionePreventivo.correnti : {}
  return (    
  <>
    <h3 style={{textAlign:'center'}}>Totali correnti</h3>
      <SummaryNumbers totalNumbers={numeriVersioneCorrente.correnti} totalNumbersPreventivo={preventivoCorrenti} />
    {numeriVersioneConfronto && 
        <div style={{ border: '2px solid #337ab7' , borderRadius: '50px', padding: '15px'}}>
        <h4>Versione comparata:</h4>
        <SummaryNumbers totalNumbers={numeriVersioneConfronto.correnti}/>
      </div>
    }

    <HR />

    <h3 style={{textAlign:'center'}}>Totali di inizio anno</h3>
    <SummaryNumbers totalNumbers={numeriVersioneCorrente.preventivo}/>

    <HR />

    <h3 style={{textAlign:'center'}}>Totali in proiezione</h3>
    <SummaryNumbers totalNumbers={numeriVersioneCorrente.proiezione}/>
    
  </>       
      
  )
}

