// @flow

import React, {Component} from 'react'
import {Modal, Button, Stack} from 'react-bootstrap'
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table'
import { CSVLink, CSVDownload } from 'react-csv'
import Icon from '@conveyal/woonerf/components/icon'
import { getComponentMessages } from '../../common/util/config'
import Loading from '../../common/components/Loading'

type Props = {
  onClose: () => void,
  show: boolean,
  serviceId: string
}

type State = {
  showModal: boolean
}

export default class CalendarTripsByCalendarModal extends Component<Props, State> {
  messages = getComponentMessages('CalendarHelpModal')
  state = {
    showModal: this.props.show,
    error: null,
    isLoaded: false,
    trips: []
  }

  componentDidMount() {
    this.fetchTrips()
  }
  
  componentDidUpdate(prevProps) {
    if(this.props.serviceId !== prevProps.serviceId) // Check if it's a new serviceId
    {
      this.fetchTrips()
    }
  }

  _close = () => {
    const {onClose} = this.props
    onClose && onClose()
  }

  scaricaCsv() {
    console.log('cucu')
  }

  fetchTrips(){
    fetch(`${process.env.PUSA_URL}/calendars/tripsperservice?schema=${this.props.namespace}&service_id=${this.props.serviceId}`)
    // per test locale, NB: aggiorna l'ip di pusa 
    // fetch(`http://192.168.222.91:3000/calendars/tripsperservice?schema=xfcp_zpxpzjvnbhjxvseviflmlu&service_id=FER6A4`) // chiodato per dev locale
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            trips: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  
  longNameFormatter(cell, row){
    return cell ? `<span title="${cell}">${cell}</span>`:''
  }
  dataFormatter(cell, row){
    return cell ? cell : ''
  }


  render () {
    const {serviceId, namespace} = this.props
    // console.log('qui', serviceId)
    // console.log('qui serviceId', serviceId)
    const {Body, Footer, Header, Title} = Modal
    const STYLE = {
       
        lipadding: {
          paddingTop: '10px',
        },
        floating: {
          float: 'left'
        },
        padding:{
          padding: '4px'
        }
    };
    const trips = this.state.trips
    if (!trips) {
      return <Loading />
    }
    const tableOptions = {
      striped: true,
      hover: true,
      exportCSV: true,
      pagination: true,
      options: {
        paginationShowsTotal: true,
        sizePerPageList: [10, 20, 50, 100]
      }
    }
    const rowAttributes = (row, index) => {
      return {
        'data-toggle': 'popover',
        'data-placement': 'bottom',
        'data-trigger': 'hover',
        'data-content': [
          'Index: ' + index,
          'ID: ' + row.id,
          'Name: ' + row.name,
        ].join(', ')
      }
    }
    const tripsTableOptions = {...tableOptions, exportCSV: false, search: true, pagination: false/*sovrascritto*/, rowAttributes}
    
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this._close}
        >
        <Header closeButton>
          <Title>{trips.length} corse utilizzano il calendario {serviceId}</Title>
        </Header>
        {(true || trips.length > 0) &&
        <Body>
          
            <Button
              href={`${process.env.PUSA_URL}/calendars/tripsperservice?schema=${this.props.namespace}&service_id=${this.props.serviceId}&mode=csv` }
              // href={`http://192.168.222.91:3000/calendars/tripsperservice?schema=xfcp_zpxpzjvnbhjxvseviflmlu&service_id=FER6A4&mode=csv` }
              
              className='pull-left'
              style={{marginLeft: '10px'}}
              bsStyle={'success'}>
              <span>
                <Icon type='download'/>
                Scarica CSV
              </span>
            </Button>
            <br />
            
            <BootstrapTable
                data={trips}
                {...tripsTableOptions}>
                <TableHeaderColumn dataSort width='50' dataField='route_short_name' dataAlign='right'>Linea</TableHeaderColumn>
                <TableHeaderColumn dataSort width='30' dataField='pattern' dataAlign='right' >Id Perc</TableHeaderColumn>
                <TableHeaderColumn dataSort width='150' dataField='pattern_name' dataFormat={this.longNameFormatter}>Percorso</TableHeaderColumn>
                <TableHeaderColumn dataSort width='50' dataField='trip_short_name' dataAlign='right' >Corsa</TableHeaderColumn>
                <TableHeaderColumn dataSort width='50' dataField='trip_id' dataAlign='right' isKey hidden>Trip ID nascosta</TableHeaderColumn>
                <TableHeaderColumn dataSort width='40' dataField='start_date' dataFormat={this.dataFormatter} dataAlign='right' headerAlign='right'>Inizio</TableHeaderColumn>
                <TableHeaderColumn dataSort width='40' dataField='end_date' dataFormat={this.dataFormatter} dataAlign='right' headerAlign='right'>Fine</TableHeaderColumn>

            </BootstrapTable>
          
        </Body>
        }
        <Footer>
          <Button onClick={this._close}>{this.messages(`close`)}</Button>
        </Footer>
      </Modal>
    )
  }
}
