import React from 'react';
import { Row, Col } from 'react-bootstrap';

export type Props = {
  totalNumbers: { [key: string]: number },
  totalNumbersPreventivo: ?{ [key: string]: number }
};

const bigNumberFormatter = (cell) => {
  return cell ? cell.toLocaleString('it', { maximumFractionDigits: 0 }) : 0;
};

// Funzione per ottenere lo stile condizionale
const getComparisonStyle = (value, reference) => {
  if (reference == null) return {};
  if (value < reference) return { color: 'green' };
  if (value > reference) return { color: 'red' };
  return {};
};

// Funzione per creare il tooltip
const getTooltipContent = (value, preventivo) => {
  const formattedValue = bigNumberFormatter(value);
  const preventivoFormatted = preventivo != null ? `Preventivo: ${bigNumberFormatter(preventivo)}` : null;
  return preventivoFormatted ? `${formattedValue} (${preventivoFormatted})` : formattedValue;
};

export default function SummaryNumbers({ totalNumbers, totalNumbersPreventivo }: Props) {
  const preventivo = totalNumbersPreventivo || {};

  return (
    <div style={{ padding: '20px' }}>
      <Row>
        <Col xs={4} className="text-center">
          <p
            title={getTooltipContent(totalNumbers.numLinee, preventivo.numLinee)}
            style={{
              marginBottom: '0px',
              fontSize: '200%',
              ...getComparisonStyle(totalNumbers.numLinee, preventivo.numLinee)
            }}
          >
            {bigNumberFormatter(totalNumbers.numLinee)}
          </p>
          <p style={{ marginBottom: '0px' }}>Numero linee</p>
        </Col>
        <Col xs={4} className="text-center">
          <p
            title={getTooltipContent(totalNumbers.numPercorsi, preventivo.numPercorsi)}
            style={{
              marginBottom: '0px',
              fontSize: '200%',
              ...getComparisonStyle(totalNumbers.numPercorsi, preventivo.numPercorsi)
            }}
          >
            {bigNumberFormatter(totalNumbers.numPercorsi)}
          </p>
          <p style={{ marginBottom: '0px' }}>Numero percorsi</p>
        </Col>
        <Col xs={4} className="text-center">
          <p
            title={getTooltipContent(totalNumbers.numCorse, preventivo.numCorse)}
            style={{
              marginBottom: '0px',
              fontSize: '200%',
              ...getComparisonStyle(totalNumbers.numCorse, preventivo.numCorse)
            }}
          >
            {bigNumberFormatter(totalNumbers.numCorse)}
          </p>
          <p style={{ marginBottom: '0px' }}>Numero corse</p>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col xs={3} style={{ minWidth: '250px' }}>
          <div>
            Totale km di linea:
            <span
              title={getTooltipContent(totalNumbers.totKmLinea, preventivo.totKmLinea)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.totKmLinea, preventivo.totKmLinea)
              }}
            >
              {bigNumberFormatter(totalNumbers.totKmLinea)}
            </span>
          </div>
          <div>
            - di cui urbani:
            <span
              title={getTooltipContent(totalNumbers.kmUrbani, preventivo.kmUrbani)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.kmUrbani, preventivo.kmUrbani)
              }}
            >
              {bigNumberFormatter(totalNumbers.kmUrbani)}
            </span>
          </div>
          <div>
            - di cui extraurbani:
            <span
              title={getTooltipContent(totalNumbers.kmExtraUrb, preventivo.kmExtraUrb)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.kmExtraUrb, preventivo.kmExtraUrb)
              }}
            >
              {bigNumberFormatter(totalNumbers.kmExtraUrb)}
            </span>
          </div>
        </Col>
        <Col xs={1}></Col>
        <Col xs={3} style={{ minWidth: '250px' }}>
          <div>
            Totale km contribuiti:
            <span
              title={getTooltipContent(totalNumbers.totKmContrib, preventivo.totKmContrib)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.totKmContrib, preventivo.totKmContrib)
              }}
            >
              {bigNumberFormatter(totalNumbers.totKmContrib)}
            </span>
          </div>
          <div>
            - di cui urbani:
            <span
              title={getTooltipContent(totalNumbers.kmContribUrbani, preventivo.kmContribUrbani)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.kmContribUrbani, preventivo.kmContribUrbani)
              }}
            >
              {bigNumberFormatter(totalNumbers.kmContribUrbani)}
            </span>
          </div>
          <div>
            - di cui extraurbani:
            <span
              title={getTooltipContent(totalNumbers.kmContribExtraUrb, preventivo.kmContribExtraUrb)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.kmContribExtraUrb, preventivo.kmContribExtraUrb)
              }}
            >
              {bigNumberFormatter(totalNumbers.kmContribExtraUrb)}
            </span>
          </div>
        </Col>
        <Col xs={1}></Col>
        <Col xs={4} style={{ minWidth: '250px' }}>
          <div>
            Totale km a conferma:
            <span
              title={getTooltipContent(totalNumbers.kmConferma, preventivo.kmConferma)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.kmConferma, preventivo.kmConferma)
              }}
            >
              {bigNumberFormatter(totalNumbers.kmConferma)}
            </span>
          </div>
          <div>
            Totale posti km di linea:
            <span
              title={getTooltipContent(totalNumbers.postiKm, preventivo.postiKm)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.postiKm, preventivo.postiKm)
              }}
            >
              {bigNumberFormatter(totalNumbers.postiKm)}
            </span>
          </div>
          <div>
            Totale posti km a conferma:
            <span
              title={getTooltipContent(totalNumbers.postiKmConferma, preventivo.postiKmConferma)}
              style={{
                float: 'right',
                ...getComparisonStyle(totalNumbers.postiKmConferma, preventivo.postiKmConferma)
              }}
            >
              {bigNumberFormatter(totalNumbers.postiKmConferma)}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
}
