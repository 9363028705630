
import React, { useState, useEffect } from 'react'
import ProduzioneKmReportLayout from '../components/ProduzioneKmReportLayout'


  
export default function ProduzioneKmReport({version, preventivedSchema, comparedVersion}) {
  // console.log('qui version', version,  preventivedVersion, comparedVersion)
  // console.log('qui comparedVersion', comparedVersion)

  const [numeriVersioneCorrente, setNumeriVersioneCorrente] = useState(null)
  const [numeriVersionePreventivo, setNumeriVersionePreventivo] = useState(null)
  const [numeriVersioneConfronto, setNumeriVersioneConfronto] = useState(null)
  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const schema = 'advi_vlqvggdczmddgrhpncexws'

  useEffect(() => {
    const fetchNumeroni = async () => {
      try {
        const fetchPromises = [];

        if (version && version.namespace) {
          fetchPromises.push(
            fetch(`${process.env.PUSA_URL}/utility/produzione-km/${version.namespace}`)
              .then(res => {
                if (!res.ok) throw new Error('Errore nella fetch Versione Corrente');
                return res.json();
              })
              .then(data => setNumeriVersioneCorrente(data))
          );
        }
        if (preventivedSchema) {
          fetchPromises.push(
            fetch(`${process.env.PUSA_URL}/utility/produzione-km/${preventivedSchema}`)
              .then(res => {
                if (!res.ok) throw new Error('Errore nella fetch Versione Preventivo');
                return res.json();
              })
              .then(data => setNumeriVersionePreventivo(data))
          );
        }

        if (comparedVersion && comparedVersion.namespace) {
          fetchPromises.push(
            fetch(`${process.env.PUSA_URL}/utility/produzione-km/${comparedVersion.namespace}`)
              .then(res => {
                if (!res.ok) throw new Error('Errore nella fetch Versione Confronto');
                return res.json();
              })
              .then(data => setNumeriVersioneConfronto(data))
          );
        } else {setNumeriVersioneConfronto(null)}
        await Promise.all(fetchPromises);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNumeroni();
  }, [version, preventivedSchema, comparedVersion]); // L'array vuoto indica che l'effetto viene eseguito solo una volta al montaggio



  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>Error: {error}</p>;

  
  return (
    <>
    { loading? (
        <p>Caricamento in corso...</p>
    ) : error ? ( <p>Error: {error}</p> )
      : (<ProduzioneKmReportLayout 
          numeriVersioneCorrente={numeriVersioneCorrente}
          numeriVersionePreventivo={numeriVersionePreventivo}
          numeriVersioneConfronto={numeriVersioneConfronto}
        />
        

    )}
    </>    
    
)}
