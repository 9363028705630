import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment'
import LogoAmp from './LogoAmp.js'
import LogoCasale from './LogoCasale.js'
import LogoBardo from './LogoBardo.js'
// import fakeData from './FakeData';

import {getConfigProperty} from '../../../../common/util/config'

const formatRouteReportData = (data) => {
	return data.map(item => {
		return ([
      {text: item.azienda},
			{text: item.codiceLinea},
			{text: item.linea.substring(item.linea.indexOf("- ") + 1)}, // elimino num linea
			{text: item.tipoServizio, alignment: 'center'},
			{text: bigNumberFormatter(item.kmDiLinea), alignment: 'right'},
			{text: bigNumberFormatter(item.kmContrib), alignment: 'right'},
			{text: bigNumberFormatter(item.kmConferma), alignment: 'right'},
		]);
	});
}

const formatRouteReportDataTotals = (routeReportDataTotals) => {
	return {
		table:{
			body: [
				[{text: 'Numero linee'},{text: bigNumberFormatter(routeReportDataTotals.correnti.numLinee), alignment: 'right'}],
				[{text: 'Numero percorsi'},{text: bigNumberFormatter(routeReportDataTotals.correnti.numPercorsi), alignment: 'right'}],
				[{text: 'Numero corse'},{text: bigNumberFormatter(routeReportDataTotals.correnti.numCorse), alignment: 'right'}],
				[{text: 'Totale km di linea'},{text: bigNumberFormatter(routeReportDataTotals.correnti.totKmLinea), alignment: 'right'}],
				[{text: '- di cui urbani'},{text: bigNumberFormatter(routeReportDataTotals.correnti.kmUrbani), alignment: 'right'}],
				[{text: '- di cui extraurbani'},{text: bigNumberFormatter(routeReportDataTotals.correnti.kmExtraUrb), alignment: 'right'}],
			]
		},
		layout: 'headerLineOnly'
	}
}
const formatValidoDalAl = (feedInfo, routeReportDataTotals) => {
	const dateFormat = getConfigProperty('application.date_format') || ''
	return {
		table:{
			body: [
				[{text: 'Cod. Contratto'},{text: feedInfo.cod_contratto, alignment: 'right'}],
				[{text: 'Validità dal'},{text: moment(feedInfo.feed_start_date).format(dateFormat), alignment: 'right'}],
				[{text: 'Validità al'}, {text: moment(feedInfo.feed_end_date).format(dateFormat), alignment: 'right'}],
				[{text: 'Totale km contribuiti'},{text: bigNumberFormatter(routeReportDataTotals.correnti.totKmContrib), alignment: 'right'}],
				[{text: 'Totale km a conferma'},{text: bigNumberFormatter(routeReportDataTotals.correnti.kmConferma), alignment: 'right'}],
				// [{text: 'Totale Posti km'},{text: bigNumberFormatter(routeReportDataTotals.totPostikm), alignment: 'right'}],
			]
		},
		layout: 'headerLineOnly'
	}
}

const bigNumberFormatter = (val) => {
	return val? val.toLocaleString('it', {maximumFractionDigits: 0}) : 0
}

export default (routeReportData, routeReportDataTotals, feedInfo) => {
	const {vfs} = vfsFonts.pdfMake;
	pdfMake.vfs = vfs;

	const formattedRouteReportData = formatRouteReportData(routeReportData)
	const routeReportDataTotalsTable = formatRouteReportDataTotals(routeReportDataTotals)
	const validoDalAlTable = formatValidoDalAl(feedInfo, routeReportDataTotals)
	// console.log('routeReportDataTotalsTable', routeReportDataTotalsTable)

	let logo = 	{
		image: 'logoAmp',
		width: 50,
		height: 50,
		absolutePosition: { x: 750, y: 30 }
	}

	if (feedInfo.feed_publisher_name.indexOf('Casale') >= 0){
		logo.image = 'logoCasale'
	}
	else if (feedInfo.feed_publisher_name.indexOf('Bardonecchia') >= 0){
		logo.image = 'logoBardonecchia'
	}

  const documentDefinition = {
		info: {
	    title: `Contratto di servizio ${feedInfo.feed_publisher_name}`,
	    author: 'Agenzia Metropolitana Piemontese',
			creator: '5T',
			producer: 'Transit Café',
	    subject: 'Contratto di servizio',
	    keywords: 'trasporto pubblico, contratto, servizio',
	  },
		pageSize: 'A4',
		pageOrientation: 'landscape',
		defaultStyle: {
	    fontSize: 10,
	  },
		// header: function() {
    //     return [
		// 			{ canvas: [ { type: 'rect', x: 30, y: 0, w: 200, h: 5, color: '#dddddd',} ] },
    //       { table: {
		// 				widths: [ '*'],
		// 				body: [[
		// 					{
		// 						border: [false, false, false, false],
		// 						fillColor: '#eeeeee',
		// 						text: 'PIRIPICCHIO'
		// 					},
    //   			]]
    //         },
    //       }
    //     ]
    // },
		footer: (currentPage, pageCount) => { return {text: 'pag. ' + currentPage.toString() + ' / ' + pageCount, alignment: 'right', margin: [10, 0] }},
		content: [
			logo,
			{text: 'Contratto di servizio', fontSize: 20, bold: true},
			{text: `${feedInfo.feed_publisher_name}`, fontSize: 20, bold: true},
			{text: 'Report Linee', fontSize: 14, style:{color: '#0b54a0', italics: true, }},
			'\n',
			// { canvas: [ { type: 'rect', x: 170, y: 0, w: 170, h: 30, r: 10, color: 'red', fillOpacity: 0.5} ] },
      {
        columns:[
          {width: '50%', ...routeReportDataTotalsTable},
          {width: '50%', ...validoDalAlTable},

        ],
        columnGap: 10
      },
			'\n',
			//routeReportDataTotalsTable,
			'\n',
			{
				table: {
					headerRows: 1,
					dontBreakRows: true,
					widths: [ '18%',  '*','auto', 20, 50, 60, 70],
					body: [
						[
							{text: 'Azienda', style: 'tableHeader', fillColor: '#eeeeee'},
							{text: 'Linea', style: 'tableHeader', fillColor: '#eeeeee',  alignment: 'left'},
							{text: 'Nome linea', style: 'tableHeader', fillColor: '#eeeeee'},
							// {text: 'Nome linea', style: 'tableHeader', fillColor: '#eeeeee'},
							// {text: 'N. percorsi', style: 'tableHeader'},
							{text: 'Tipo', style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
							{text: 'km linea', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
							{text: 'km contrib', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
							{text: 'km conferma', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
						],
						...formattedRouteReportData,
					]
				},
				layout: 'lightHorizontalLines', // optional
			}
		],
		images: {
			...LogoAmp, ...LogoCasale, ...LogoBardo
		//bee: 'data:iFTkSuQmCC'
		},
  }
	const pdf = pdfMake.createPdf(documentDefinition)
	// pdf.download()
	pdf.open()
}
