

import Icon from '@conveyal/woonerf/components/icon'
import React, {Component, PropTypes} from 'react'
import moment from 'moment'
import {Button} from 'react-bootstrap'
import CalendarHelpModal from './CalendarHelpModal'
import CalendarTripsByCalendarModal from './CalendarTripsByCalendarModal'
import DayPicker from 'react-day-picker'

import { getComponentMessages } from '../../common/util/config'

type Props = {
  activeEntity: Entity,
  updateActiveGtfsEntity: any,
  activeComponent: string,
}

type State = {
  showHelpModal: boolean,
  showTripsModal: boolean,
}

export default class CalendarForm extends Component<Props, State> {
  messages = getComponentMessages('CalendarForm')

 

  state = {
    showHelpModal: false,
    showTripsModal: false,
  }

  _hideHelpModal = () => this.setState({showHelpModal: false})
  _showHelpModal = () => this.setState({showHelpModal: true})

  _hideTripsModal = () => this.setState({showTripsModal: false})
  _showTripsModal = () => this.setState({showTripsModal: true})

  handleDayClick = (day, { daysAdded, daysExcluded, weeklyDefault, disabled })  => {
    const {activeComponent, activeEntity, updateActiveGtfsEntity} = this.props
    //console.log('handleDayClick(): activeEntity', activeEntity)
    const cdates = activeEntity.calendar_dates ? [...activeEntity.calendar_dates] : []
    // console.log('cdates', cdates)
    const gtfsDate =  moment(day).format('YYYYMMDD')
    let returnCalDates = []

    if (disabled) { // Day is disabled, do nothing
      return
    }
    if (daysAdded) {
      returnCalDates = cdates.filter(cdate => cdate.date != gtfsDate)
      console.log('deleting from addedDays ', gtfsDate)
    }
    else if (daysExcluded) {
      returnCalDates = cdates.filter(cdate => cdate.date != gtfsDate)
      console.log('deleting from excludedDays ', gtfsDate)
    }
    else if (weeklyDefault) {
      cdates.push({date: gtfsDate, exception_type: 2})
      returnCalDates = [].concat(cdates)
      console.log('adding to excludedDays ', gtfsDate)
    }
    else {
      cdates.push({date: gtfsDate, exception_type: 1})
      returnCalDates = [].concat(cdates)
      // console.log('adding to addedDays ', gtfsDate)
    }
    // console.log('updating with calendar_dates:', returnCalDates);
    updateActiveGtfsEntity({
      component: activeComponent,
      entity: activeEntity,
      props: {calendar_dates: returnCalDates}
    })
  }

  // moment to new Date (2018-06-25)
  m2d = (gtfsDay) => {
    return new Date(moment(gtfsDay).format('YYYY-MM-DD'))
  }

  // moment to month new Date (2018, 0)
  m2m = (gtfsDay) => {
    return new Date(moment(gtfsDay).format('YYYY'), moment(gtfsDay).format('M')-1)
  }

  render () {
    const {activeComponent, activeEntity, selectedWeekDays, excludedDays, includedDays, numberActiveDays, namespace} = this.props
    const {service_id} = activeEntity // per CalendarTripsModal
    // console.log('qui namespace', namespace)
    const showHelpModal = {
      backgroundColor: 'white'
    }

    const start_month = activeEntity.start_date ? this.m2m(activeEntity.start_date) : new Date(moment().format('YYYY'), moment().format('M')-1)
    const start_date  = activeEntity.start_date ? this.m2d(activeEntity.start_date) : new Date(moment().format('YYYY-MM-DD'))
    const end_date    = activeEntity.end_date ? this.m2d(activeEntity.end_date) : new Date(moment().format('YYYY'), moment().format('M')+3)

    const excludedDaysToDates = excludedDays.map(this.m2d)
    const includedDaysToDates = includedDays.map(this.m2d)

    const dayPickerStyle = '.DayPicker:focus, .DayPicker-wrapper:focus, .DayPicker-Months:focus,  .DayPicker-Day:focus{outline: none}'
    const dayPickerHoverStyle = `
    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
            background-color: #dff0d8; border-radius: 50%;
    }`
    const DayPickerDayStyle = `.DayPicker-Day {width: 14px; height: 14px; padding: 4px 5px}`
    const weeklyDefaultStyle = '.DayPicker-Day--weeklyDefault {background-color:#9fc68f; border-radius: 0}'
    // const daysAddedStyle = '.DayPicker-Day--daysAdded {border: 2px solid #9fc68f; font-weight: 1000; background-image: url("http://www.5t.torino.it/wp-content/themes/5t/images/ico-footer-torino.png");}' //border: 2px solid #9fc68f
    const daysAddedStyle = `.DayPicker-Day--daysAdded {background-position: center; background-repeat: no-repeat; background-image: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width='20' height='20'><circle cx='10' cy='10' r='9' stroke='forestgreen' stroke-width='2' fill='none'/></svg>")}` //border: 2px solid #9fc68f
    // const daysExcludedStyle = '.DayPicker-Day--daysExcluded {border: 2px solid #c60d03}'
    const daysExcludedStyle = `.DayPicker-Day--daysExcluded {background-position: center; background-repeat: no-repeat; background-image: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width='20' height='20'><circle cx='10' cy='10' r='9' stroke='rgb(198, 13, 3)'  stroke-width='2' fill='none'/></svg>")}`
    const outsideStyle = '.DayPicker-Day--outside, .DayPicker-Day--disabled {border: none; background: none}'

    const countDayStyle = {float: 'right', width:'15%', color:'#3c763d', margin: 16, borderRadius: 5}

    const selectedIsoWeekDays = selectedWeekDays.map( (e) => e % 7)
    // console.log('selectedWeekDays', selectedWeekDays)
    // console.log('selectedIsoWeekDays', selectedIsoWeekDays)

    return (
      <div>
      <div style={{paddingRight:15}}>
        <ButtonGroup className='pull-right'>
          <Button            
            onClick={this._showHelpModal}            
            title='Mostra legenda utilizzo calendario'
          >
            <Icon type='question' />
          </Button>
          <Button
            onClick={this._showTripsModal}            
            title='Mostra quali corse utilizzano questo calendario'
          >
            <Icon type='info' />
          </Button>
          

        </ButtonGroup>
        <CalendarHelpModal
          onClose={this._hideHelpModal}
          show={this.state.showHelpModal} />
        <CalendarTripsByCalendarModal
          serviceId={service_id}
          namespace={namespace}
          onClose={this._hideTripsModal}
          show={this.state.showTripsModal} />
      </div>
        <div style={{clear:'both'}} >{this.props.children}</div>

        <div style={countDayStyle} className='input-group-addon'>{numberActiveDays} giorni di servizio</div>
        <style>{DayPickerDayStyle} {dayPickerHoverStyle} {dayPickerStyle} {weeklyDefaultStyle} {daysAddedStyle} {daysExcludedStyle} {outsideStyle}</style>
        <DayPicker
          numberOfMonths={12}
          firstDayOfWeek={1}
          weekStartsOn={1}
          locale="it"
          months={ ['Gennaio', 'Febbraio','Marzo','Aprile','Maggio','Giugno','Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre', ]}
          weekdaysShort={["Do","Lu","Ma","Me","Gi","Ve","Sa"]}
          month={start_month}
          modifiers={{
            disabled: {before: start_date, after: end_date},
            weeklyDefault: {daysOfWeek: selectedIsoWeekDays}, // selectedWeekDays: isoweekday
            daysAdded: includedDaysToDates,
            daysExcluded: excludedDaysToDates
          }}
          onDayClick={this.handleDayClick}
        />
      </div>
    )
  }
}
