// @flow
import { connect } from 'react-redux'

import RoutesReportLayout from '../components/RoutesReportLayout'
import { fetchFeedInfo } from '../../../../gtfs/actions/feedInfo'
import { fetchAgencies } from '../../../../gtfs/actions/agencies'
import { fetchRouteReport } from '../../../../gtfs/actions/routes'
import { fetchCalendars } from '../../../../gtfs/actions/calendars'
import {
   getRouteReportData,
  //  getCsvLinea,
   getCsvPreventivo,
   getCsvProiezione
  } from '../../../selectors'

import type {dispatchFn, AppState} from '../../../../types/reducers'




const mapStateToProps = (state: AppState, ownProps) => {
  const {namespace} = ownProps.version
  const {gtfs} = state
  const {filter, routes} = gtfs
  const {routeOffset} = filter
  const {data, fetchStatus} = routes.routeReport
  return {
    fetchStatus,
    namespace,
    allRoutes: state.gtfs.routes.allRoutes,
    numRoutes: data ? data.numRoutes : 0,
    // routeData: getRouteData(state),
    routeReportData: getRouteReportData(state),
    // csvLinea: getCsvLinea(state),  // usiamo direttamente routeReportData
    csvPreventivo:  getCsvPreventivo(state),
    csvProiezione:  getCsvProiezione(state),
    // routeReportDataTotals: getRouteReportDataTotals(state), // i totaloni servono nel pdf, sostituito con fetch in layout
    feedInfo: state.gtfs.feedInfo.data.feed_info,
    routeOffset
  }
}

const mapDispatchToProps = (dispatch: dispatchFn, ownProps) => {
  const {namespace} = ownProps.version
  return {
    onComponentMount: (fetched) => {
      if (!fetched) {
        dispatch(fetchFeedInfo(namespace))
        dispatch(fetchAgencies(namespace))
        dispatch(fetchCalendars(namespace))
        // dispatch(fetchRoutes(namespace))
        dispatch(fetchRouteReport(namespace))
      }
    },
  }
}

const RoutesReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutesReportLayout)

export default RoutesReport
